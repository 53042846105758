import graphme from '@unitz/graphme';

import hasuraClient from '@vl/mod-clients/hasuraApp';

import GetCourseTemplate from 'unitz-gql/course_template/GetCourseTemplate.gql';

graphme.GqlBuilder.loadDocument({
  GetCourseTemplate,
});

class CourseTemplateModel extends graphme.BaseModel {
  static DEFINITION = graphme.Definition.create({
    name: 'CourseTemplate',
    schema: {
      id: String,
    },
    nodes: [],
    key: 'id',

    baseQuery: 'GetCourseTemplate',

    GQL_ACTIONS: {
      FIND: 'b2b_course_template',
      GET: 'b2b_course_template_by_pk',
      INSERT: 'insert_b2b_course_template_one',
      UPDATE: 'update_b2b_course_template_by_pk',
    },

    getClient: hasuraClient.getClient,
  });

  static getClient(...args) {
    return hasuraClient.getClient(...args);
  }

  static async createCourseTemplate({ object }) {
    const query = hasuraClient.gql`
      mutation insert_b2b_course_template_one($object: b2b_course_template_insert_input = {}) {
        insert_b2b_course_template_one(
          object: $object
        ) {
          id
          name
          photo_url
        }
      }  
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        object,
      });

      const data = rtn;
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }

  static async insertLessons({ lessons }) {
    const query = hasuraClient.gql`
      mutation insert_b2b_course_template_lessons($lessons: [b2b_course_template_lesson_insert_input!] = {}) {
        insert_b2b_course_template_lesson(objects: $lessons) {
          affected_rows
          returning {
            template_id
          }
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        lessons,
      });

      const data = rtn;
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }

  static async updateCourseTemplate({ account_id, id, set_course_template, lessons }) {
    const query = hasuraClient.gql`
      mutation updateCourseTemplate($account_id: uuid!, $id: uuid!, $set_course_template: b2b_course_template_set_input = {}, $lessons: [b2b_course_template_lesson_insert_input!]!) {
        course_template: update_b2b_course_template_by_pk(pk_columns: {id: $id}, _set: $set_course_template) {
          id
        }
        delete_b2b_course_template_lesson(where: {account_id: {_eq: $account_id}, template_id: {_eq: $id}}) {
          affected_rows
          returning {
            account_id
          }
        }
        lessons: insert_b2b_course_template_lesson(objects: $lessons, on_conflict: {constraint: course_template_lesson_pkey, update_columns: id}) {
          affected_rows
          returning {
            id
          }
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        account_id,
        id,
        set_course_template,
        lessons,
      });

      const data = rtn;
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }

  static async deleteCourseTemplate({ template_id, account_id, user_id }) {
    const query = hasuraClient.gql`
      mutation deleteCourseTemplate($template_id: uuid!, $account_id: uuid!, $user_id: String!) {
        delete_b2b_course_template_by_pk(id: $template_id) {
          account_id
        }
        delete_b2b_course_template_lesson(where: {account_id: {_eq: $account_id}, user_id: {_eq: $user_id}, template_id: {_eq: $template_id}}) {
          affected_rows
          returning {
            account_id
          }
        }
      }
    `;
    try {
      const rtn = await hasuraClient.getClient().request(query, {
        template_id,
        account_id,
        user_id,
      });

      const data = rtn;
      if (data) {
        return data;
      }
    } catch (err) {
      console.log(err);
    }

    return null;
  }
}

graphme.model({ CourseTemplateModel });

export default CourseTemplateModel;
