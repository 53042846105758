import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            modalState: hook(() => {
              const [isModalVisible, $isModalVisible] = React.useState(false);
              return {
                isModalVisible,
                $isModalVisible,
              };
            }),
          },
        },
      ],
    ],
  },
});
export default bindData;
