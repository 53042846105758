import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import _ from 'lodash';
import gstyles from '@vl/gstyles';
import Link from '@uz/unitz-components-web/Link';
import i18n from 'i18n-js';

export const GuideBubble = () => {
  const locale = i18n.locale;
  const guideUrl = `https://unitz.biz/${locale}/guides`;
  return (
    <DIV>
      <div style={{ bottom: 30 }} className="fixed right-6 shadow-md rounded-full cursor-pointer">
        <Link external target="_blank" to={guideUrl}>
          {gstyles.icons({ name: 'guide', size: 61 })}
        </Link>
      </div>
    </DIV>
  );
};

export default GuideBubble;
