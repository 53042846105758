import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import Button from '@uz/unitz-components-web/Button';
import gstyles from '@vl/gstyles';
import ModalProvider from '@uz/unitz-providers/ModalProvider';
import ZoomToolPrepareLessonModal from '@uz/unitz-tool-components/ZoomToolPrepareLessonModal';

const Index = ({ size }) => {
  return (
    <DIV className="component">
      <ModalProvider>
        <Button
          size={size ? size : 'medium'}
          name="ink"
          style={{ borderColor: gstyles.colors.border }}
          onClick={() => {
            ctx.apply('modalModel.show', () => {
              return <ZoomToolPrepareLessonModal />;
            });
          }}
          block
        >
          {ctx.apply('i18n.t', 'ZoomToolCalendar.prepare_lesson')}
        </Button>
      </ModalProvider>
    </DIV>
  );
};

export default displayName(Index);
