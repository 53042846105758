import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import _ from 'lodash';
import { useRenderProps } from '@vl/hooks/useRenderProps';
import { Row, Col } from 'antd';
import moment from 'moment';
import gstyles from '@vl/gstyles';
import { START_TIME_FORMAT_TIME_ZONE_FULL } from '@vl/mod-utils/datetime';
import ZoomToolPrepareLessonAction from '@uz/unitz-tool-components/ZoomToolPrepareLessonAction';
import { Link } from '@uz/unitz-components-web/Link';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';
import Button from '@uz/unitz-components-web/Button';
import i18n from 'i18n-js';

const renderCourseItem = useRenderProps(
  ({ item, index }) => {
    const locale = i18n.locale;

    const statusStyle = {
      draft: gstyles.colors.powerPeony,
      upcoming: gstyles.colors.powerPeony,
      ongoing: gstyles.colors.yellow500,
      finished: gstyles.colors.brandb500,
      completed: gstyles.colors.brandb500,
    };
    return (
      <DIV forceCtx>
        {ctx.debug(() => {
          ctx.set('@item', item);
          ctx.set('@index', index);
        })}
        <div className="px-8 py-6 w-full bg-white500 rounded-lg">
          <Row gutter={[24, 24]} align="middle" justify="space-between">
            <Col>
              <Link to={ctx.apply('routeStore.toUrl', 'toolAccountCourseDetail', ctx.get('@item'))}>
                <div className="uppercase text-base font-semibold">{ctx.get('@item.name')}</div>
              </Link>
              <div className="start_date my-1">
                <span className="text-sub font-semibold text-center text-xs mr-1">
                  {ctx.apply('i18n.t', 'ZoomToolCourse.Table.startDate')}:
                </span>
                <span className="text-xs">{CourseFormatter.startAt(ctx)(ctx.get('@item'))}</span>
              </div>
              <div className="my-1">
                <span className="text-sub font-semibold text-center text-xs mr-1">
                  {ctx.apply('i18n.t', 'ZoomToolCourse.Table.lesson_progress')}:
                </span>
                <span className="text-xs">
                  {[
                    _.get(ctx.get('@item'), 'ongoing_course_room_aggregate.aggregate.count', 0),
                    _.get(ctx.get('@item'), 'total_course_room_aggregate.aggregate.count', 0),
                  ].join('/')}
                </span>
              </div>
              <div className="flex items-center my-2">
                <span className="text-sub font-semibold text-center text-xs mr-2">
                  {ctx.apply('i18n.t', 'ZoomToolMember.Table.status.col_name')}:
                </span>
                <span className="text-xs">
                  <div className="flex items-center justify-center">
                    <div
                      style={{ backgroundColor: _.get(statusStyle, _.get(ctx.get('@item'), 'status', ''), '') }}
                      className={`capitalize inline-block text-xs font-semibold text-white500 rounded-lg text-center px-2`}
                    >
                      {ctx.apply('i18n.t', `ZoomToolCourse.Status.${_.get(ctx.get('@item'), 'status', '')}`)}
                    </div>
                  </div>
                </span>
              </div>
            </Col>
            <Col>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Link to={ctx.apply('routeStore.toUrl', 'toolAccountCourseEdit', ctx.get('@item'))}>
                    <Button block size="medium" type="primary">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Action.edit_course')}
                    </Button>
                  </Link>
                </Col>
                <Col span={24}>
                  <Link to={ctx.apply('routeStore.toUrl', 'toolAccountCourseReports', ctx.get('@item'))}>
                    <Button
                      block
                      style={{ border: `1px solid ${gstyles.colors.placeHolder}` }}
                      size="medium"
                      type="outline"
                    >
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Action.view_report')}
                    </Button>
                  </Link>
                </Col>
                <Col span={24}>
                  <Link to={ctx.apply('routeStore.toUrl', 'toolAccountCourseTeachers', ctx.get('@item'))}>
                    <Button
                      block
                      style={{ border: `1px solid ${gstyles.colors.placeHolder}` }}
                      size="medium"
                      type="outline"
                    >
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Action.edit_teachers')}
                    </Button>
                  </Link>
                </Col>
                <Col span={24}>
                  <Link to={ctx.apply('routeStore.toUrl', 'toolAccountCourseStudents', ctx.get('@item'))}>
                    <Button
                      block
                      style={{ border: `1px solid ${gstyles.colors.placeHolder}` }}
                      size="medium"
                      type="outline"
                    >
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Action.edit_student')}
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </DIV>
    );
  },
  ({ item, index }) => [index, _.get(item, 'id')]
);

export default renderCourseItem;
