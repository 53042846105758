import React from 'react';
import { bindings, hook } from '@vl/redata';
import { useFormik } from 'formik';
import AssetModelFormatter from '@uz/unitz-models/AssetModel/formatter';
import _ from 'lodash';
import { notification } from 'antd';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';
import i18n from 'i18n-js';
import { START_DATE_YEAR_ML_FORMAT } from '@vl/mod-utils/datetime';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            form: hook((ctx) => {
              const Yup = ctx.get('ValidateHandler.Yup');
              const account_id = ctx.apply('accountModel.getAccountId');
              const user_id = ctx.apply('currentUserModel.getUserId');
              const form = useFormik({
                initialValues: {
                  user_id,
                  name: ctx.get('@item.name'),
                  start_at: ctx.get('@item.start_at'),
                  photo_url: !ctx.get('@item.photo_url')
                    ? ''
                    : [
                        {
                          url: ctx.get('@item.photo_url'),
                          status: 'success',
                        },
                      ],
                  description: ctx.get('@item.description'),
                  link_urls: _.map(ctx.get('@item.link_urls'), (item, index) => {
                    return {
                      id: index,
                      content: item,
                    };
                  }),
                  materials: _.map(ctx.get('@item.materials'), AssetModelFormatter.urlToFile()),
                },
                onSubmit: async (values, actions) => {
                  try {
                    const room_id = _.get(ctx.get('@item'), 'id', '');
                    const client = await getClient();

                    const object = {
                      name: values.name,
                      start_at: values.start_at,
                      description: values.description,
                      link_urls: `{${_.map(_.castArray(values.link_urls, []), 'content').join(',')}}`,
                      materials: _.castArray(values.materials, []),
                    };

                    const resData = await client.request(
                      gql`
                        mutation UpdateCourseRoom(
                          $where: b2b_course_room_bool_exp!
                          $_set: b2b_course_room_set_input = {}
                        ) {
                          update_b2b_course_room(where: $where, _set: $_set) {
                            returning {
                              id
                              name
                            }
                          }
                        }
                      `,
                      { where: { id: { _eq: room_id } }, _set: object }
                    );

                    if (_.has(resData, 'errors')) {
                      return notification.error({
                        message: _.get(resData, 'errors'),
                      });
                    }
                    notification.success({
                      message: ctx.apply('i18n.t', 'ZoomToolCourse.Notification.edit_lesson_success'),
                    });
                    ctx.apply('modalModel.hide');
                  } catch (err) {
                    console.log(err);
                  }
                },
                validationSchema: Yup.object().shape({
                  // name: Yup.string().required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                }),
              });

              _.assign(form, {
                canSubmit: !form.isSubmitting && form.dirty && form.isValid,
                dateFormatter: (value) => {
                  const locale = i18n.locale;
                  const TIME_FORMAT = 'HH:mm';
                  const session_duration = ctx.get('form.values.session_duration') || 60 * 60;
                  const endValue = value.clone().add(session_duration, 'second');

                  return `${value.format(
                    `${_.get(START_DATE_YEAR_ML_FORMAT, locale, 'de')} ${TIME_FORMAT}`
                  )} - ${endValue.format(TIME_FORMAT)}`;
                },
                getUploaderProps: AssetModelFormatter.getUploaderProps(ctx, form),
              });

              return form;
            }),
          },
        },
      ],
    ],
  },
});
export default bindData;
