import React from 'react';
import gstyles from '@vl/gstyles';
import { Menu } from 'antd';
import styled from 'styled-components';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import elementResizeDetectorMaker from 'element-resize-detector';
import useForceUpdate from '@vl/hooks/useForceUpdate';

const StyledMenu = styled(Menu)`
  .ant-menu-overflow-item.ant-menu-item {
    &:after {
      display: none !important;
    }
    &:hover {
      border-bottom: 0px !important;
    }
    top: 0px;
    border-bottom: 0px !important;
  }
  &.ant-menu-horizontal {
    border-bottom: 0px !important;
    .ant-menu-item {
      margin: 0px 4px !important;
      &.ant-menu-overflow-item.ant-menu-item-only-child {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  &.ant-menu-item-only-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ActionMenu = ({ align, ...props }) => {
  const computedProps = { ...props };
  const ref = React.useRef({
    erd: elementResizeDetectorMaker({}),
    id: _.uniqueId('elementResizeDetectorMaker'),
    containerRect: { width: 0, height: 0 },
  });
  const forceUpdate = useForceUpdate(1000);

  React.useEffect(() => {
    let eleRef = document.querySelector(`#${ref.current.id}`);
    eleRef = eleRef && eleRef.parentNode;
    ref.current.erd.listenTo(eleRef, (element) => {
      const width = element.offsetWidth;
      const height = element.offsetHeight;
      const containerRect = { width, height };
      if (!_.isEqual(containerRect, ref.current.containerRect)) {
        ref.current.containerRect = containerRect;
        forceUpdate();
      }
    });
  }, []);
  let width = _.get(ref.current, 'containerRect.width', 0);
  width = Math.max(width - 36, 36);
  const alignStyle = align === 'right' ? { justifyContent: 'flex-end' } : {};

  return (
    <div style={{ ...alignStyle, width }} id={ref.current.id}>
      <StyledMenu
        mode="horizontal"
        {...computedProps}
      />
    </div>
  );
};

ActionMenu.Item = StyledMenuItem;

export default displayName(ActionMenu);
