import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import gstyles from '@vl/gstyles';
import { Col, Row, Upload, Progress, Modal } from 'antd';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import { Form, FormItem, DatePicker } from 'formik-antd';
import Button from '@uz/unitz-components-web/Button';
import InputQuillEditor from '@uz/unitz-components-web/InputQuillEditor';
import InputText from '@uz/unitz-components-web/InputText';
import _ from 'lodash';
import styled from 'styled-components';
import './modal.scss';
import { Image } from '@uz/unitz-components-web/Image';
import moment from 'moment';
import CustomInput from '@uz/unitz-components-web/CustomInput';
import i18n from 'i18n-js';
import { datePickerLocaleMapping } from '@vl/mod-utils/datePickerLocaleMapping';

const CoverPhotoInput = styled(Upload.Dragger)`
  .ant-upload.ant-upload-btn {
    padding: 0px;
  }
`;

const AttachmentInputContainer = styled(CustomInput)`
  .ant-upload-list-item {
    .ant-upload-list-item-progress {
      padding-right: 32px;
    }
  }
`;
const AttachmentInput = styled(Upload.Dragger)`
  .ant-upload.ant-upload-btn {
    padding: 0px;
  }
`;

const CustomEditor = styled.div`
  .text-editor {
    .ql-toolbar {
      border-radius: 8px 8px 0 0;
      border-color: ${gstyles.colors.border};
    }
    .quill {
      .ql-container {
        border-radius: 0 0 8px 8px;
        border-color: ${gstyles.colors.border};
        .ql-editor {
          &::before {
            color: ${gstyles.colors.placeHolder};
          }
        }
      }
    }
  }
`;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  layout: 'vertical',
};

const gutter = {
  xs: 16,
  sm: 16,
  md: 16,
  lg: 16,
};

const SESSION_START_TIME_FORMAT = 'HH:mm';

const View8 = () => {
  const locale = i18n.locale;
  return (
    <DIV className="component">
      <Modal
        bodyStyle={{ padding: 0 }}
        width={1080}
        footer={null}
        className="rounded-lg overflow-hidden shadow-lg"
        maskStyle={{ background: gstyles.colors.black300 }}
        style={{ zIndex: 1035 }}
        visible={true}
        centered
        wrapClassName={'my-modal'}
        onCancel={() => {
          ctx.apply('modalModel.hide');
        }}
        closable={false}
      >
        <div className="px-4 md:px-12 py-4">
          <FormProvider form={ctx.get('form')}>
            <Form {...layout} className="lesson-form">
              <CustomInput className="mb-3">
                <FormItem
                  label={
                    <span className="text-sm font-semibold text-sub">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Form.lesson_time')}
                    </span>
                  }
                  name={`start_at`}
                >
                  <DatePicker
                    allowClear={false}
                    size="middle"
                    showTime={{
                      hourStep: 1,
                      minuteStep: 15,
                      format: SESSION_START_TIME_FORMAT,
                    }}
                    suffixIcon={gstyles.icons({
                      name: 'calendar',
                      size: 20,
                      fill: gstyles.colors.sub,
                    })}
                    format={ctx.get('form.dateFormatter')}
                    locale={datePickerLocaleMapping(locale)}
                    name={`start_at`}
                    className="w-full"
                    disabled
                    disabledDate={(current) => {
                      return current && current < moment().valueOf();
                    }}
                  />
                </FormItem>
              </CustomInput>
              <FormItem
                name={`name`}
                label={
                  <span className="text-sm font-semibold text-sub">
                    {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.lesson_title')}
                  </span>
                }
              >
                <CustomInput>
                  <InputText name={`name`} size="medium" />
                </CustomInput>
              </FormItem>
              {/* <FormItem
                name={`photo_url`}
                label={
                  <span className="text-sm font-semibold text-sub">
                    {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.cover')}
                  </span>
                }
              >
                <CustomInput>
                  <div className="relative">
                    <CoverPhotoInput
                      {...ctx.apply('form.getUploaderProps', {
                        fieldName: `photo_url`,
                        multiple: false,
                      })}
                      itemRender={() => null}
                    >
                      {!ctx.get(`form.values.photo_url.0`) ? (
                        <div className="flex flex-col items-center justify-center py-10">
                          <div className="text-center ant-upload-text">
                            {gstyles.icons({ name: 'photo-library', size: 24, fill: gstyles.colors.sub })}
                          </div>
                          <div className="text-sm my-2 font-normal ant-upload-hint text-main">
                            {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.cover_placeholder')}
                          </div>
                        </div>
                      ) : (
                        <div className="flex items-center justify-center">
                          <Image
                            alt="photo"
                            src={ctx.get(`form.values.photo_url.0.url`)}
                            className="overflow-hidden rounded-lg"
                            width={364}
                            height={205}
                          />
                        </div>
                      )}
                    </CoverPhotoInput>
                    {ctx.get(`form.values.photo_url.0`) && (
                      <div className="absolute top-0 right-0">
                        <Button type="link" name="ink" onClick={() => ctx.apply('form.setFieldValue', `photo_url`, [])}>
                          <span>{gstyles.icons({ name: 'delete', size: 16, fill: gstyles.colors.sub })}</span>
                        </Button>
                      </div>
                    )}
                    {ctx.get(`form.values.photo_url.0.status`) === 'uploading' && (
                      <div className="absolute inset-0 flex items-center justify-center p-4 bg-black opacity-50">
                        <Progress width={80} type="circle" percent={ctx.get(`form.values.photo_url.0.percent`)} />
                      </div>
                    )}
                  </div>
                  <div className="flex items-center mt-2">
                    <div>{gstyles.icons({ name: 'info', size: 16, fill: gstyles.colors.sub })}</div>
                    <div style={{ paddingLeft: 4 }} className="text-sub text-xs font-normal">
                      {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.cover_hint')}
                    </div>
                  </div>
                </CustomInput>
              </FormItem> */}
              <FormItem
                name={`description`}
                label={
                  <span className="text-sm font-semibold text-sub">
                    {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.description')}
                  </span>
                }
              >
                <CustomEditor>
                  <InputQuillEditor name={`description`} toolbarId="lesson-description" />
                </CustomEditor>
              </FormItem>
              <FormItem
                name={`materials`}
                label={
                  <span className="text-sm font-semibold text-sub">
                    {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.material')}
                  </span>
                }
              >
                <div className="flex items-center mb-2">
                  <div>{gstyles.icons({ name: 'info', size: 16, fill: gstyles.colors.sub })}</div>
                  <div style={{ paddingLeft: 4 }} className="text-sub text-xs font-normal">
                    {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.material_hint')}
                  </div>
                </div>
                <AttachmentInputContainer>
                  <AttachmentInput
                    {...ctx.apply('form.getUploaderProps', {
                      fieldName: `materials`,
                    })}
                  >
                    <div className="flex flex-row items-center justify-center py-10">
                      <Button name="ink">{ctx.apply('i18n.t', 'Course.Info.Action.select_files')}</Button>
                      <div className="flex flex-row items-center justify-center ml-4">
                        <span>{gstyles.icons({ name: 'cloud-upload', size: 24, fill: gstyles.colors.sub })}</span>
                        <div className="text-base font-normal text-main pl-2">
                          {ctx.apply('i18n.t', 'Course.Info.Action.drop_files_here')}
                        </div>
                      </div>
                    </div>
                  </AttachmentInput>
                </AttachmentInputContainer>
              </FormItem>
              <div className="mb-6">
                {_.map(ctx.get(`form.values.link_urls`), (lesson, linkIndex) => {
                  return (
                    <Row key={_.get(lesson, 'id', '')} gutter={gutter}>
                      <Col span={22}>
                        <CustomInput>
                          <FormItem name={`link_urls[${linkIndex}].content`}>
                            <InputText
                              name={`link_urls[${linkIndex}].content`}
                              size="medium"
                              placeholder={ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.url_placeholder')}
                            />
                          </FormItem>
                        </CustomInput>
                      </Col>

                      <Col span={2}>
                        <div
                          className="cursor-pointer inline-block"
                          onClick={() => ctx.apply('form.array.remove', `link_urls`, linkIndex)}
                        >
                          {gstyles.icons({
                            name: 'remove-circle',
                            fill: gstyles.colors.red500,
                            size: 24,
                          })}
                        </div>
                      </Col>
                    </Row>
                  );
                })}
                <Row className="my-1" gutter={gutter}>
                  <Col xs={16} lg={6}>
                    <Button
                      type="outline"
                      size="small"
                      className="w-full"
                      onClick={() => ctx.apply('form.array.add', `link_urls`)}
                      icon={gstyles.icons({
                        name: 'add-circle',
                        size: 20,
                        fill: gstyles.colors.brandb500,
                        className: 'inline-block mx-2',
                      })}
                    >
                      {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.add_link')}
                    </Button>
                  </Col>
                </Row>
                <Row justify="end" className="my-4" gutter={gutter}>
                  <Col xs={16} lg={6}>
                    <Button
                      type="primary"
                      size="medium"
                      className="w-full"
                      loading={ctx.get('form.isSubmitting')}
                      onClick={ctx.get('form.submitForm')}
                      disabled={!ctx.get('form.canSubmit')}
                    >
                      {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Action.save')}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </FormProvider>
        </div>
      </Modal>
    </DIV>
  );
};

export default displayName(View8);
